import React from 'react';
import PropTypes from 'prop-types';
import MarvelLoader from '@marvelapp/marvel-ui/loader';

import styles from './styles.css';

export default function Button({
  children,
  onClick,
  appearance,
  disabled,
  isLoading,
}) {
  if (isLoading) {
    return (
      <span className={styles.disabled}>
        <div className={styles.loading}>
          <MarvelLoader size="large" />
        </div>
      </span>
    );
  }

  if (disabled) {
    return (
      <span className={styles.disabled} onClick={onClick}>
        {children}
      </span>
    );
  }
  return (
    <span className={styles[appearance]} onClick={onClick}>
      {children}
    </span>
  );
}

Button.propTypes = {
  onClick: PropTypes.function,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  appearance: PropTypes.oneOf(Object.keys(styles)).isRequired,
};

Button.defaultProps = {
  outlined: false,
  disabled: false,
  appearance: 'normal',
  isLoading: false,
};
