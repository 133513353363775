import React, { Component } from 'react';

import styles from './styles.css';
import RadioInput from './RadioInput';
import GenderPercentage from './GenderPercentage';
import UsedColors from './UsedColors';

export default class Red extends Component {
  componentDidMount() {
    this.props.onSelectFilter('simplTritanopia');
  }

  render() {
    const { activeFilter, onSelectFilter } = this.props;
    return (
      <div>
        <h2 className={styles.header}>Blue type color blindness</h2>
        <p className={styles.paragraph}>
          This is how your designs appear to those with blue-type color
          deficiency
        </p>

        <div className={styles.colorsUsing}>
          <h3>Colors you're using</h3>
          <UsedColors />
        </div>

        <h3 className={styles.severity}>Severity</h3>

        <div className={styles.radioButtons}>
          <div className={styles.radio}>
            <RadioInput
              filter="simplTritanomaly"
              label="Weak blue-type vision"
              sublabel="Tritanomaly"
              activeFilter={activeFilter}
              onSelectFilter={onSelectFilter}
            />

            <RadioInput
              filter="simplTritanopia"
              label="Missing blue-type vision"
              sublabel="Tritanopia"
              activeFilter={activeFilter}
              onSelectFilter={onSelectFilter}
            />
          </div>
        </div>

        <h3 className={styles.severity}>Affects</h3>
        <GenderPercentage gender="male" percentage="0.1" />
        <GenderPercentage gender="female" percentage="0.05" />
      </div>
    );
  }
}
