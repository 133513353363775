import React from 'react';
// import Button from '@marvelapp/marvel-ui/button';

import OauthStart from '../../../components/OauthStart';
import Button from '../../../components/Button';

import Arrow from './arrow';
import Logo from './logo';

import Main from '../images/main.png';
import Main2x from '../images/main@2x.png';

import styles from './styles.css';

export default function Header() {
  const redirectUri =
    process.env.NODE_ENV === 'production'
      ? 'https://colors.marvelapp.com/auth'
      : 'http://localhost:3000/auth';

  let shareURL;

  const changeShareURL = e => {
    console.log(`Value changed to ${e.target.value}`);
    shareURL = e.target.value;
  };

  const goToPrototype = () => {
    const pathOnly = shareURL.replace(
      /https:\/\/(.*?\.)marvelapp.com\/(.+?)\/.*/,
      '$2'
    );
    const fancyURL = `/project/${pathOnly}?emb=1`;
    window.location = fancyURL;
  };

  return (
    <div className={styles.block}>
      <div className={styles.blockInside}>
        <div>
          <img alt="color blindness" src={Main} srcSet={`${Main2x} 2x`} />
        </div>
        <div className={styles.textContent}>
          <h3 className={styles.blockTitle}>Designing for color-blindness</h3>
          <p className={styles.blockSubtitle}>
            Make your designs easy to navigate for more people
          </p>

          <input
            className={styles.input}
            placeholder="Share URL (eg marvelapp.com/qrqfe)"
            type="text"
            onChange={changeShareURL}
          />
          <div className={styles.generate}>
            <Button appearance="normal" onClick={goToPrototype}>
              Generate
            </Button>
          </div>
          <OauthStart
            scopes={
              'user:read projects:read projects.comments:write company.projects:read company:read'
            }
            redirectUri={redirectUri}
          />
        </div>
      </div>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.scrollDownNotice}>
        <div className={styles.scrollDownText}>Why should I care?</div>
        <Arrow />
      </div>
    </div>
  );
}
