import React from 'react';

import styles from './styles.css';

export default function RadioInput({
  activeFilter,
  onSelectFilter,
  filter,
  label,
  sublabel,
}) {
  return (
    <div className={styles.radioInput}>
      <div className={styles.left}>
        <input
          className={styles.radioButton}
          type="radio"
          checked={activeFilter === filter}
          onClick={() => onSelectFilter(filter)}
        />
      </div>

      <div className={styles.right}>
        <div className={styles.radioLabel}>{label}</div>
        {sublabel && <div className={styles.radioSublabel}>{sublabel}</div>}
      </div>
    </div>
  );
}
