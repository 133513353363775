import React from 'react';

export default function arrow() {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9">
      <path
        d="M15.291.652v-.01c.292.241.453.563.453.966 0 .342-.131.644-.382.915l-5.543 5.533c-1.036 1.036-2.636 1.036-3.672 0l-5.472-5.472c-.463-.473-.573-1.147-.191-1.67.443-.644 1.418-.724 1.931-.201l5.603 5.543 5.472-5.472c.523-.523 1.288-.573 1.801-.131z"
        fill="#021647"
      />
    </svg>
  );
}
