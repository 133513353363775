/* eslint-disable */
// Not sure why this shit is needed, probably a babel related issue

window.requestFrame = (function() {
  var raf =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    function(fn) {
      return window.setTimeout(fn, 20);
    };
  return function(fn) {
    return raf(fn);
  };
})();

window.cancelFrame = (function() {
  var cancel =
    window.cancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.clearTimeout;
  return function(id) {
    return cancel(id);
  };
})();

window.resetTriggers = function resetTriggers(element) {
  var triggers = element.__resizeTriggers__,
    expand = triggers.firstElementChild,
    contract = triggers.lastElementChild,
    expandChild = expand.firstElementChild;
  contract.scrollLeft = contract.scrollWidth;
  contract.scrollTop = contract.scrollHeight;
  expandChild.style.width = expand.offsetWidth + 1 + 'px';
  expandChild.style.height = expand.offsetHeight + 1 + 'px';
  expand.scrollLeft = expand.scrollWidth;
  expand.scrollTop = expand.scrollHeight;
};

window.checkTriggers = function checkTriggers(element) {
  return (
    element.offsetWidth != element.__resizeLast__.width ||
    element.offsetHeight != element.__resizeLast__.height
  );
};

window.scrollListener = function scrollListener(e) {
  var element = this;
  resetTriggers(this);
  if (this.__resizeRAF__) cancelFrame(this.__resizeRAF__);
  this.__resizeRAF__ = requestFrame(function() {
    if (checkTriggers(element)) {
      element.__resizeLast__.width = element.offsetWidth;
      element.__resizeLast__.height = element.offsetHeight;
      element.__resizeListeners__.forEach(function(fn) {
        fn.call(element, e);
      });
    }
  });
};
