import React, { Component } from 'react';
import createPrototypeViewer from '@marvelapp/prototype-viewer';

import PlayerControls from '../../components/PlayerControls';
import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';

import uploadToWeTransfer from '../../utils/uploadToWeTransfer';
import { filterPrototype } from '../../utils/prototype';

import styles from './styles.css';

export default class ProjectView extends Component {
  state = {
    project: null,
    transformedProject: null,
    processing: false,
    downloadIsEnabled: false,
  };

  componentDidMount() {
    const { fancyUrl } = this.props;
    const prototypeUrl = `https://cors.io/?https://marvelapp.com/api/prototype/${fancyUrl}/`;

    fetch(prototypeUrl)
      .then(response => response.json())
      .then(project => {
        this.setState({
          project,
        });
      });
  }

  onChangeFilter = filterName => {
    if (!filterName) {
      this.setState({
        transformedProject: null,
        downloadIsEnabled: false,
      });
    }

    this.setState({
      processing: true,
      downloadIsEnabled: true,
    });
    filterPrototype(this.state.project, filterName).then(transformedProject => {
      this.setState({ transformedProject, processing: false });
    });
  };

  onChangeTab = tabName => {
    if (tabName === 'normal') {
      this.setState({
        transformedProject: null,
        downloadIsEnabled: false,
      });
    }
  };

  onDownload = async (filterName, tabName) => {
    if (!this.state.downloadIsEnabled) {
      return;
    }
    console.log(`Downloading all the images as ${filterName}, ${tabName}`);
    return filterPrototype(this.state.project, filterName).then(
      async transformedProject => {
        const files = [];
        let image;
        for (image in transformedProject.images) {
          files.push({
            filename:
              transformedProject.images[image].display_name ||
              transformedProject.images[image].name,
            variations: [
              {
                variationName: filterName,
                base64: transformedProject.images[image].url,
              },
            ],
          });
        }
        console.log('Data to zip:');
        console.log(files);
        const transferURL = await uploadToWeTransfer(
          transformedProject.name,
          files
        );
        window.open(transferURL, '_blank');
        return transferURL;
      }
    );
  };

  render() {
    const { project, transformedProject, processing } = this.state;
    if (!project) {
      return <Loader>Loading...</Loader>;
    }

    const args = [
      {},
      {
        areKeyboardControlsEnabled: false,
        userIsAuthenticated: false,
        userIsInHandoffTestGroup: false,
        userIsSuperUser: false,
        isDownloaded: true,
        isAppBuild: false,
        userRole: 'Viewer',
      },
    ];
    const PrototypeViewer = createPrototypeViewer(...args);

    PrototypeViewer.setProject(transformedProject || project);
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          {processing ? (
            <Loader>Processing images...</Loader>
          ) : (
            <PrototypeViewer />
          )}
          <PlayerControls />
        </div>

        <div className={styles.sidebar}>
          <Sidebar
            onChangeFilter={this.onChangeFilter}
            onChangeTab={this.onChangeTab}
            onDownload={this.onDownload}
          />
        </div>
      </div>
    );
  }
}
