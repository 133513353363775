import React, { Component } from 'react';
import cx from 'classnames';
import map from 'lodash/map';

import DownloadButton from './DownloadButton';

import styles from './styles.css';
import types from './types.css';

import Red from './Red';
import Green from './Green';
import Blue from './Blue';
import Mono from './Mono';
import Normal from './Normal';

const tabs = {
  normal: {
    button: <span>Normal</span>,
    component: Normal,
  },
  red: {
    button: <span>Red</span>,
    component: Red,
  },
  green: {
    button: <span>Green</span>,
    component: Green,
  },
  blue: {
    button: <span>Blue</span>,
    component: Blue,
  },
  mono: {
    button: <span>Mono</span>,
    component: Mono,
  },
};

export default class Sidebar extends Component {
  state = {
    activeTab: 'normal',
    activeFilter: null,
    downloadIsEnabled: false,
    downloadIsGenerating: false,
  };

  switchTab = tabName => {
    if (tabName === 'normal') {
      this.setState({
        activeFilter: null,
        downloadIsEnabled: false,
        downloadIsGenerating: false,
      });

      this.props.onChangeTab(tabName);
    }

    this.setState({
      activeTab: tabName,
      downloadIsGenerating: false,
    });
  };

  selectFilter = filterName => {
    this.setState({
      activeFilter: filterName,
      downloadIsEnabled: true,
      downloadIsGenerating: false,
    });
    this.props.onChangeFilter(filterName);
  };

  download = () => {
    this.setState({
      downloadIsGenerating: true,
    });
    this.props
      .onDownload(this.state.activeFilter, this.state.activeTab)
      .then(() => {
        this.setState({
          downloadIsGenerating: false,
        });
      });
  };

  render() {
    const { activeTab, activeFilter } = this.state;
    const ComponentTab = tabs[activeTab].component;
    const mainClass = cx([styles.sidebar, types[activeTab]]);
    return (
      <div className={mainClass}>
        <div className={styles.tabs}>
          {map(tabs, ({ button }, tabName) => {
            const buttonClass = styles[tabName];
            const buttonActiveClass = styles[`${tabName}-active`];
            const isActive = tabName === activeTab;
            return (
              <span
                className={cx(styles.switchyButton, buttonClass, {
                  [buttonActiveClass]: isActive,
                })}
                onClick={() => this.switchTab(tabName)}
              >
                {button}
              </span>
            );
          })}
        </div>
        <ComponentTab
          activeFilter={activeFilter}
          onSelectFilter={this.selectFilter}
        />
        {activeTab !== 'normal' && (
          <div className={styles.downloadContainer}>
            <div className={styles.downloadLeft}>
              Download all images for this color blindness
            </div>
            <div className={styles.downloadRight}>
              <DownloadButton
                isLoading={this.state.downloadIsGenerating}
                disabled={!this.state.downloadIsEnabled}
                onClick={this.download}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
