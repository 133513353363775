import React, { Component } from 'react';
import gql from 'fake-tag';
import { Connect, query } from 'urql';
import Link from '@marvelapp/marvel-ui/link';
import Logo from './logo';

import Button from '../../components/Button';

import styles from './styles.css';

const getProjectsQuery = gql`
  query {
    user {
      projects(first: 9999) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            pk
            name
            prototypeUrl
            createdAt
            modifiedAt
            screens(first: 1) {
              edges {
                node {
                  content {
                    ... on ImageScreen {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.onProjectChange = this.onProjectChange.bind(this);
    // this.handleSignUp = this.handleSignUp.bind(this);
    this.state = {
      selectedFancyURL: null,
    };
  }

  onProjectChange(event) {
    const fancyName = event.target.value;
    const selectedFancyURL = fancyName ? `/project/${fancyName}` : null;
    this.setState({
      selectedFancyURL,
    });
  }

  render() {
    return (
      <Connect query={query(getProjectsQuery)}>
        {({ loaded, data }) => {
          if (!loaded) return null;

          const projects = data.user.projects.edges.map(({ node: project }) => {
            const { name, pk, prototypeUrl } = project;
            const embed = prototypeUrl.replace('https://marvelapp.com/', '');
            return (
              <option key={pk} value={embed}>
                {name}
              </option>
            );
          });

          projects.unshift(<option key="empty" />);

          return (
            <div>
              <div className={styles.logo}>
                <Logo />
              </div>
              <div className={styles.container}>
                <div className={styles.inside}>
                  <div className={styles.title}>Select your prototype</div>
                  <select
                    className={styles.select}
                    onChange={this.onProjectChange}
                  >
                    {projects}
                  </select>
                  <Link
                    url={this.state.selectedFancyURL}
                    isDisabled={!this.state.selectedFancyURL}
                  >
                    <Button disabled={!this.state.selectedFancyURL}>
                      Generate
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </Connect>
    );
  }
}
