import cloneDeep from 'lodash/cloneDeep';
import promiseMemoize from 'promise-memoize';
import { createFilteredImage } from './colours';

const filterPrototype = promiseMemoize(async (projectData, filter) => {
  const projectDataCopy = cloneDeep(projectData);
  await Promise.all(
    Object.values(projectDataCopy.images).map(imageData =>
      applyImageFilter(imageData, filter)
    )
  );
  return projectDataCopy;
});

async function applyImageFilter(imageData, filter) {
  const imageElement = await loadImageAsync(imageData.url);
  // eslint-disable-next-line no-param-reassign
  imageData.url = createFilteredImage(imageElement, filter);
}

function loadImageAsync(url) {
  return new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = url;
    image.onload = () => {
      resolve(image);
    };
  });
}

export { filterPrototype };
