import createWTClient from '@wetransfer/js-sdk';
import JSZip from 'jszip';
import { weTransferKey } from '../config';

let wtClient;

(async function() {
  // An authorization call is made when you create the client.
  // Keep that in mind to perform this operation
  // in the most suitable part of your code
  wtClient = await createWTClient(weTransferKey);
})();

function stripData(base64Content) {
  return base64Content.replace(/data:.*?;base64,/, '');
}

function base64ToBuffer(base64Content) {
  const stringToBuffer = stripData(base64Content);
  return Buffer.from(stringToBuffer, 'base64');
}

/*
EXAMPLE USE:
let uploadFiles = async function() {
  const transferURL = await uploadToWeTransfer('my-prototype', files);
  console.log('Transfer URL is now', transferURL);
}
<h2>Development</h2>
<a onClick={uploadFiles}>
  <Button>Send sample files to WeTransfer</Button>
</a>
 */

export default async function uploadToWeTransfer(name, files) {
  const zip = new JSZip();
  const mainFolder = zip.folder(name);
  await files.forEach(async file => {
    const filenameFolder = mainFolder.folder(file.filename);
    await file.variations.forEach(variation => {
      filenameFolder.file(`${variation.variationName}.png`, stripData(variation.base64), {
        base64: true,
      });
    });
  });
  const transferURL = await zip
    .generateAsync({
      type: 'base64',
    })
    .then(async base64Content => {
      console.log('WETRANSFER Doing it');
      const content = base64ToBuffer(base64Content);
      const transfer = await wtClient.transfer.create({
        message: name,
        files: [
          {
            name: `${name}.zip`,
            size: content.length,
            content,
          },
        ],
      });
      return transfer.url;
    });
  console.log('TransferURL is', transferURL);
  return transferURL;
}
