import React, { Component } from 'react';

import MarvelLoader from '@marvelapp/marvel-ui/loader';
import styles from './styles.css';

// Only renders after "timeout" ms have passed, to avoid flash of loading component
export default class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMessage: false,
    };
    this.enableMessage = this.enableMessage.bind(this);
    this.timer = setTimeout(this.enableMessage, 2500000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage() {
    this.setState({ displayMessage: true });
  }

  render() {
    const { children } = this.props;
    const { displayMessage } = this.state;

    if (!displayMessage) {
      return null;
    }

    return (
      <div className={styles.loader}>
        <MarvelLoader size="large" />
        {children}
      </div>
    );
  }
}

Loader.defaultProps = {
  timeout: 250,
};
