import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, Client } from 'urql';
import { Router } from '@reach/router';

import '@marvelapp/marvel-ui/lib/styles.css';
import '@marvelapp/prototype-viewer/lib/marvel-prototype-viewer.css';
import './index.css';

import './detect-element-resize';
import AuthLanding from './pages/AuthLanding';
import Home from './pages/Home';
import ProjectList from './pages/ProjectList/index';
import ProjectView from './pages/ProjectView';

const client = new Client({
  url: 'https://marvelapp.com/graphql',
  fetchOptions() {
    return {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    };
  },
});

ReactDOM.render(
  <Provider client={client}>
    <Router>
      <Home path="/" />
      <AuthLanding path="/auth" />
      <ProjectList path="/projects" />
      <ProjectView path="/project/:fancyUrl" />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// ಠ_ಠ
window.client = client;
