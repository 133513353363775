import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

function objectToQuerystring(obj) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
}

function redirectToAuth(scopes, redirectUri) {
  const params = {
    client_id: 'vTVnN11Ky6fIgwpPCs7M8HTOeIIMLHVUECPtsqh4',
    response_type: 'token',
    redirect_uri: redirectUri,
    scope: scopes,
    approval_prompt: 'auto',
  };
  const qs = objectToQuerystring(params);
  const url = `https://marvelapp.com/oauth/authorize/?${qs}`;
  window.location = url;
}

function OauthStart({ appId, scopes, redirectUri }) {
  return (
    <Button
      appearance="outlined"
      onClick={redirectToAuth.bind(this, scopes, redirectUri)}
    >
      Connect w/ Marvel
    </Button>
  );
}

OauthStart.propTypes = {
  appId: PropTypes.string,
  scopes: PropTypes.string,
  redirectUri: PropTypes.string,
};

export default OauthStart;
