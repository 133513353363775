import React from 'react';
import { trigger } from 'mousetrap';

import styles from './styles.css';

export default function PlayerControls() {
  return (
    <div style={{ position: 'absolute', bottom: 24, left: 24 }}>
      <RoundedButton icon={<Home />} onClick={() => trigger('h')} />
      <RoundedButton icon={<NavigateLeft />} onClick={() => trigger('left')} />
      <RoundedButton
        icon={<NavigateRight />}
        onClick={() => trigger('right')}
      />
    </div>
  );
}

function RoundedButton({ onClick, icon }) {
  return (
    <div className={styles.roundedButton} onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
    </div>
  );
}

function Home() {
  return (
    <div
      style={{
        marginBottom: 1,
      }}
    >
      <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
        <g
          id="Playground"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Step-3"
            transform="translate(-43.000000, -850.000000)"
            fill="#FFFFFF"
          >
            <g id="Controls" transform="translate(28.000000, 837.000000)">
              <g id="Home" transform="translate(0.945312, 0.804434)">
                <path
                  d="M31.6044922,20.15625 L31.6220703,20.15625 C32.1142578,20.6044922 32.1494141,21.28125 31.7363281,21.7910156 C31.5166016,22.0107422 31.2353516,22.125 30.8925781,22.125 C30.6201172,22.125 30.3564453,22.0283203 30.1015625,21.84375 L23.0175781,15.2080078 L15.9335938,21.84375 C15.4765625,22.2919922 14.7646484,22.2480469 14.3515625,21.7910156 C13.9121094,21.3339844 13.9472656,20.6220703 14.4042969,20.2089844 L21.4882812,13.5732422 C22.3320312,12.7822266 23.6767578,12.7822266 24.5205078,13.5732422 L31.6044922,20.15625 Z M17.3925781,22.125 L23.0175781,17.0625 L28.6425781,22.125 L28.6425781,28.3125 C28.6425781,28.9365234 28.1416016,29.4375 27.5175781,29.4375 L24.7050781,29.4375 L24.7050781,24.9375 L21.3300781,24.9375 L21.3300781,29.4375 L18.5175781,29.4375 C17.8935547,29.4375 17.3925781,28.9365234 17.3925781,28.3125 L17.3925781,22.125 Z"
                  id="home"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

function NavigateLeft() {
  return (
    <div
      style={{
        marginRight: 2,
      }}
    >
      <svg width="10px" height="17px" viewBox="0 0 10 17" version="1.1">
        <g
          id="Playground"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Step-3"
            transform="translate(-99.000000, -851.000000)"
            fill="#FFFFFF"
          >
            <g id="Controls" transform="translate(28.000000, 837.000000)">
              <g id="Left" transform="translate(54.945312, 0.804434)">
                <path
                  d="M24.8252101,29.1284193 L24.8352694,29.1284193 C24.5938466,29.4201386 24.2719496,29.5810871 23.8695782,29.5810871 C23.5275626,29.5810871 23.2257841,29.4503164 22.9541834,29.1988343 L17.4215775,23.6561691 C16.3854713,22.620063 16.3854713,21.0206369 17.4215775,19.9845307 L22.8938277,14.5122805 C23.366614,14.0495535 24.040586,13.9389013 24.5636688,14.3211541 C25.2074629,14.7637626 25.2879372,15.7395131 24.7648544,16.2525365 L19.2221893,21.8555574 L24.6944395,27.3278076 C25.2175222,27.8508903 25.2678186,28.6153959 24.8252101,29.1284193 Z"
                  id="navigateleft"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

function NavigateRight() {
  return (
    <div
      style={{
        marginLeft: 2,
      }}
    >
      <svg width="10px" height="17px" viewBox="0 0 10 17" version="1.1">
        <g
          id="Playground"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Step-3"
            transform="translate(-155.000000, -851.000000)"
            fill="#FFFFFF"
          >
            <g id="Controls" transform="translate(28.000000, 837.000000)">
              <g id="Right" transform="translate(108.945312, 0.804434)">
                <path
                  d="M26.8252101,29.1284193 L26.8352694,29.1284193 C26.5938466,29.4201386 26.2719496,29.5810871 25.8695782,29.5810871 C25.5275626,29.5810871 25.2257841,29.4503164 24.9541834,29.1988343 L19.4215775,23.6561691 C18.3854713,22.620063 18.3854713,21.0206369 19.4215775,19.9845307 L24.8938277,14.5122805 C25.366614,14.0495535 26.040586,13.9389013 26.5636688,14.3211541 C27.2074629,14.7637626 27.2879372,15.7395131 26.7648544,16.2525365 L21.2221893,21.8555574 L26.6944395,27.3278076 C27.2175222,27.8508903 27.2678186,28.6153959 26.8252101,29.1284193 Z"
                  id="navigateright"
                  transform="translate(22.884819, 21.835465) scale(-1, 1) translate(-22.884819, -21.835465) "
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

function IconContainer({ children }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
}
