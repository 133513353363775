import { navigate } from '@reach/router';

export default function AuthLanding() {
  const accessToken = document.location.href.match(
    /#(?:access_token)=([\S\s]*?)&/
  )[1];
  localStorage.setItem('accessToken', accessToken);
  setTimeout(() => navigate('/projects'), 0);
  return null;
}
