import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Logo from './logo';

import styles from './styles.css';

export default function DownloadButton({ disabled, onClick, isLoading }) {
  return (
    <div className={styles.container}>
      <Button disabled={disabled} onClick={onClick} isLoading={isLoading}>
        <div className={styles.flex}>
          <div className={styles.download}>Download</div>
          <div className={styles.logo}>
            <Logo />
          </div>
        </div>
      </Button>
    </div>
  );
}

DownloadButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
