import React, { Component } from 'react';

import styles from './styles.css';
import RadioInput from './RadioInput';
import GenderPercentage from './GenderPercentage';
import UsedColors from './UsedColors';

export default class Red extends Component {
  componentDidMount() {
    this.props.onSelectFilter('simplProtanopia');
  }

  render() {
    const { activeFilter, onSelectFilter } = this.props;
    return (
      <div>
        <h2 className={styles.header}>Red type color blindness</h2>
        <p className={styles.paragraph}>
          This is how your designs appear to those with red-type color
          deficiency
        </p>

        <div className={styles.colorsUsing}>
          <h3>Colors you're using</h3>
          <UsedColors />
        </div>

        <h3 className={styles.severity}>Severity</h3>

        <div className={styles.radioButtons}>
          <div className={styles.radio}>
            <RadioInput
              filter="simplProtanomaly"
              label="Weak red-type vision"
              activeFilter={activeFilter}
              onSelectFilter={onSelectFilter}
              sublabel="Protanomaly"
            />
          </div>

          <div className={styles.radio}>
            <RadioInput
              filter="simplProtanopia"
              label="Missing red-type vision"
              activeFilter={activeFilter}
              onSelectFilter={onSelectFilter}
              sublabel="Protanopia"
            />
          </div>
        </div>

        <h3 className={styles.severity}>Affects</h3>
        <GenderPercentage gender="male" percentage="2.5" />
        <GenderPercentage gender="female" percentage="0.2" />
      </div>
    );
  }
}
