import React from 'react';

function Male() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19">
      <path
        d="M2.59 6.039c-2.799 2.799-2.805 7.346-.007 10.144 2.799 2.799 7.362 2.809 10.161.01 2.473-2.473 2.733-6.341.872-9.097l2.228-2.228.09 1.213 2.349-.204-.426-5.033-5.107-.518-.225 2.364 1.369.15-2.282 2.282c-2.788-1.828-6.578-1.527-9.021.916zm8.148 8.148c-1.701 1.701-4.47 1.701-6.172 0-1.702-1.702-1.702-4.47 0-6.172s4.47-1.702 6.172 0 1.702 4.47 0 6.172z"
        fill="#021647"
      />
    </svg>
  );
}

export default Male;
