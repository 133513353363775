import React from 'react';

function Logo() {
  return (
    <svg
      width="29"
      height="15"
      viewBox="0 0 29 15"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-1"
          points="0 0.117763242 15.8286178 0.117763242 15.8286178 13.1830911 0 13.1830911"
        />
      </defs>
      <g id="Playground" fill="none" fillRule="evenodd">
        <g id="Step-3" transform="translate(-1186 -835)">
          <g id="Sidebar" transform="translate(176 -49)">
            <g id="Footer" transform="translate(704 786)">
              <g id="We-Transfer-Button" transform="translate(206 82.804)">
                <g id="WeTransfer-Logo" transform="translate(100.207 16)">
                  <path
                    d="M23.3660556,13.4149465 C25.4764362,13.4149465 27.0845236,12.761778 27.9388109,11.8572469 C28.3908605,11.3546656 28.9184917,10.5758158 28.5417357,9.97289102 C28.2150795,9.44525987 27.6623984,9.3447724 27.009086,9.67128467 C26.3559175,9.99808487 25.6273114,10.1738659 24.7980739,10.1738659 C23.9940303,10.1738659 23.1398868,9.87240356 22.6876933,9.42006602 C22.1348683,8.86738498 21.8334059,8.16382879 21.8334059,7.33487922 C21.8334059,7.13376033 21.9590872,7.10856649 22.185112,7.28449153 C22.7631308,7.73682906 23.6172743,7.96285387 24.7728801,7.96285387 C26.9085985,7.96285387 28.7929544,6.53083557 28.7929544,4.3198235 C28.7929544,1.98313012 26.5819423,0.249505471 23.2403743,0.249505471 C19.4968565,0.249505471 16.607482,2.66149246 16.607482,6.73181049 C16.607482,10.525716 19.1199565,13.4149465 23.3660556,13.4149465"
                    id="Fill-1"
                    fill="#FFF"
                  />
                  <g id="Group-5" transform="translate(0 .026)">
                    <mask id="mask-2" fill="#fff">
                      <use xlinkHref="#path-1" />
                    </mask>
                    <path
                      d="M7.91429453,9.41437941 C8.31624437,9.41437941 8.54241314,9.64040421 8.81882564,10.0675479 L10.0248191,11.9519037 C10.4770127,12.6805098 10.8539127,13.1830911 11.7332499,13.1830911 C12.6125872,13.1830911 13.014681,12.831241 13.4418247,11.8514163 C13.9694559,10.6454228 14.5221369,9.18821064 15.0247182,7.22841722 C15.5773992,5.0421671 15.8286178,3.68544241 15.8286178,2.57993638 C15.8286178,1.47457431 15.4767678,0.821261848 14.2709182,0.595093078 C12.6125872,0.293630695 10.3765252,0.117705656 7.91429453,0.117705656 C5.4520638,0.117705656 3.21600185,0.293630695 1.55767082,0.595093078 C0.35167732,0.821261848 -2.87929687e-05,1.47457431 -2.87929687e-05,2.57993638 C-2.87929687e-05,3.68544241 0.251189859,5.0421671 0.804014859,7.22841722 C1.30645216,9.18821064 1.8591332,10.6454228 2.38676435,11.8514163 C2.81390804,12.831241 3.21600185,13.1830911 4.09533912,13.1830911 C4.97467638,13.1830911 5.35157634,12.6805098 5.80376992,11.9519037 L7.00976341,10.0675479 C7.28617591,9.64040421 7.51234468,9.41437941 7.91429453,9.41437941"
                      id="Fill-3"
                      fill="#FFF"
                      mask="url(#mask-2)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
