import React from 'react';
import 'babel-polyfill';
import 'regenerator-runtime';

import Block from './Block';
import Header from './Header';
import Footer from './Footer';

import Deficiency from './images/deficiency.png';
import Deficiency2x from './images/deficiency@2x.png';
import Facebook from './images/facebook.png';
import Facebook2x from './images/facebook@2x.png';
import Hereditary from './images/hereditary.png';
import Hereditary2x from './images/hereditary@2x.png';
import Meat from './images/meat.png';
import Meat2x from './images/meat@2x.png';
import MenWoman from './images/men-woman.png';
import MenWoman2x from './images/men-woman@2x.png';

import styles from './styles.css';

export default function Home() {
  return (
    <div className={styles.container}>
      <Header />
      <Block
        title={
          <div>
            1 in 12 men
            <br />1 in 200 woman{' '}
            <span>
              <br />
              have a form of color blindness
            </span>
          </div>
        }
        subtitle="That's a total of 300 million people worldwide, which means you could reach significantly more users by optimising for color blindness."
        image={MenWoman}
        image2x={MenWoman2x}
        caption="men and woman"
        appearance="green"
      />

      <Block
        title={
          <div>
            Rumor has it that...{' '}
            <span>
              Facebook is blue because the founder, being red-green color-blind,
              found it easiest to see.
            </span>
          </div>
        }
        subtitle="Allegedly"
        image={Facebook}
        image2x={Facebook2x}
        caption="facebook"
        appearance="white"
        reversed
      />

      <Block
        title={
          <div>
            Color vision deficiency is common,{' '}
            <span>color blindness is very rare</span>
          </div>
        }
        subtitle="People are “color blind” only if they see just black and gray. This
        is actually very rare. The more common condition is “color vision
        deficiency,” where greens, reds and less commonly blues are harder to tell apart.."
        image={Deficiency}
        image2x={Deficiency2x}
        caption="deficiency"
        appearance="red"
      />

      <Block
        title={
          <div>
            Real life example:
            <span>
              Some people with red-green colorblindness can find it hard to tell
              if their meat is cooked
            </span>
          </div>
        }
        subtitle="Because they see reds less vibrantly, it can be harder to distinguish between shades of red and brown."
        image={Meat}
        image2x={Meat2x}
        caption="cooking meat"
        appearance="white"
        reversed
      />

      <Block
        title={
          <div>
            Color blindness is hereditary,{' '}
            <span>
              and is passed from mother to son and is carried on the X
              chromosome
            </span>
          </div>
        }
        subtitle="However, it can also be caused by eye diseases, aging or retina damage."
        image={Hereditary}
        image2x={Hereditary2x}
        appearance="blue"
        caption="hereditary"
      />
      <Footer />
    </div>
  );
}
