import React from 'react';
// import Button from '@marvelapp/marvel-ui/button';

import Button from '../../../components/Button';

import styles from './styles.css';

export default function Header() {
  return (
    <div className={styles.block}>
      <div className={styles.blockInside}>
        <h3 className={styles.title}>Raise awarness for color blindness</h3>
        <p className={styles.subtitle}>
          Why not share this page with your friends and family?
        </p>
        <Button appearance="inversed">Share</Button>
      </div>
    </div>
  );
}
