import React from 'react';

function Male() {
  return (
    <svg width="17" height="23" viewBox="0 0 17 23">
      <path
        d="M6.833 16.382v2.054h-1.237v2.598h1.237v1.856h3.093v-1.856h1.361v-2.598h-1.361v-2.032c3.588-.698 6.382-3.905 6.382-7.748 0-4.351-3.55-7.89-7.9-7.89-4.351 0-7.875 3.539-7.875 7.89 0 3.801 2.712 6.982 6.3 7.726zm1.565-12.522c2.645 0 4.797 2.152 4.797 4.797 0 2.645-2.152 4.797-4.797 4.797-2.645 0-4.797-2.152-4.797-4.797 0-2.645 2.152-4.797 4.797-4.797z"
        fill="#021647"
      />
    </svg>
  );
}

export default Male;
