/* eslint-disable */

import NProgress from 'nprogress';
// Code grabbed from http://web.archive.org/web/20090318054431/http://www.nofunc.com/Color_Blindness_Library
// Added 2 missing } to fix code.
// Used Lookup table for Math.pow(<>/255, gamma)
/*

This function allows you to see what colors look like to those who are color blind.

Use the fBlind[] in order to convert. For instance: fBlind['Tritanomaly'](RGB) would convert RGB[] into Tritanomaly.

*/
/*

    The Color Blind Simulation function is
    copyright (c) 2000-2001 by Matthew Wickline and the
    Human-Computer Interaction Resource Network ( http://hcirn.com/ ).

    It is used with the permission of Matthew Wickline and HCIRN,
    and is freely available for non-commercial use. For commercial use, please
    contact the Human-Computer Interaction Resource Network ( http://hcirn.com/ ).

*/

var rBlind = {
  protan: { cpu: 0.735, cpv: 0.265, am: 1.273463, ayi: -0.073894 },
  deutan: { cpu: 1.14, cpv: -0.14, am: 0.968437, ayi: 0.003331 },
  tritan: { cpu: 0.171, cpv: -0.003, am: 0.062921, ayi: 0.292119 },
};

var fBlind = {
  Normal: function(v) {
    return v;
  },
  Protanopia: function(v) {
    return blindMK(v, 'protan');
  },
  Protanomaly: function(v) {
    return anomylize(v, blindMK(v, 'protan'));
  },
  Deuteranopia: function(v) {
    return blindMK(v, 'deutan');
  },
  Deuteranomaly: function(v) {
    return anomylize(v, blindMK(v, 'deutan'));
  },
  Tritanopia: function(v) {
    return blindMK(v, 'tritan');
  },
  Tritanomaly: function(v) {
    return anomylize(v, blindMK(v, 'tritan'));
  },
  Achromatopsia: function(v) {
    return monochrome(v);
  },
  Achromatomaly: function(v) {
    return anomylize(v, monochrome(v));
  },
};

const powGammaLookup = Array(256);
(function() {
  var i;
  for (i = 0; i < 256; i++) {
    powGammaLookup[i] = Math.pow(i / 255, 2.2);
  }
})();

function blindMK(rgb, t) {
  var gamma = 2.2;
  var wx = 0.312713;
  var wy = 0.329016;
  var wz = 0.358271;

  var b = rgb[2];
  var g = rgb[1];
  var r = rgb[0];

  var cr = powGammaLookup[r];
  var cg = powGammaLookup[g];
  var cb = powGammaLookup[b];
  // rgb -> xyz
  var cx = 0.430574 * cr + 0.34155 * cg + 0.178325 * cb;
  var cy = 0.222015 * cr + 0.706655 * cg + 0.07133 * cb;
  var cz = 0.020183 * cr + 0.129553 * cg + 0.93918 * cb;

  var sum_xyz = cx + cy + cz;
  var cu = 0;
  var cv = 0;

  if (sum_xyz !== 0) {
    cu = cx / sum_xyz;
    cv = cy / sum_xyz;
  }

  var nx = (wx * cy) / wy;
  var nz = (wz * cy) / wy;
  var clm;
  var dy = 0;

  if (cu < rBlind[t].cpu) {
    clm = (rBlind[t].cpv - cv) / (rBlind[t].cpu - cu);
  } else {
    clm = (cv - rBlind[t].cpv) / (cu - rBlind[t].cpu);
  }

  var clyi = cv - cu * clm;
  var du = (rBlind[t].ayi - clyi) / (clm - rBlind[t].am);
  var dv = clm * du + clyi;

  var sx = (du * cy) / dv;
  var sy = cy;
  var sz = ((1 - (du + dv)) * cy) / dv;
  // xzy->rgb
  var sr = 3.063218 * sx - 1.393325 * sy - 0.475802 * sz;
  var sg = -0.969243 * sx + 1.875966 * sy + 0.041555 * sz;
  var sb = 0.067871 * sx - 0.228834 * sy + 1.069251 * sz;

  var dx = nx - sx;
  var dz = nz - sz;
  // xzy->rgb
  var dr = 3.063218 * dx - 1.393325 * dy - 0.475802 * dz;
  var dg = -0.969243 * dx + 1.875966 * dy + 0.041555 * dz;
  var db = 0.067871 * dx - 0.228834 * dy + 1.069251 * dz;

  var adjr = dr ? ((sr < 0 ? 0 : 1) - sr) / dr : 0;
  var adjg = dg ? ((sg < 0 ? 0 : 1) - sg) / dg : 0;
  var adjb = db ? ((sb < 0 ? 0 : 1) - sb) / db : 0;

  var adjust = Math.max(
    adjr > 1 || adjr < 0 ? 0 : adjr,
    adjg > 1 || adjg < 0 ? 0 : adjg,
    adjb > 1 || adjb < 0 ? 0 : adjb
  );

  sr = sr + adjust * dr;
  sg = sg + adjust * dg;
  sb = sb + adjust * db;

  return [inversePow(sr), inversePow(sg), inversePow(sb)];
}

function inversePow(v) {
  return 255 * (v <= 0 ? 0 : v >= 1 ? 1 : Math.pow(v, 1 / 2.2));
}

function anomylize(a, b) {
  var v = 1.75,
    d = v * 1 + 1;

  return [
    (v * b[0] + a[0] * 1) / d,
    (v * b[1] + a[1] * 1) / d,
    (v * b[2] + a[2] * 1) / d,
  ];
}

function monochrome(r) {
  var z = Math.round(r[0] * 0.299 + r[1] * 0.587 + r[2] * 0.114);
  return [z, z, z];
}

// Source: http://web.archive.org/web/20081014161121/http://www.colorjack.com/labs/colormatrix/
// Another Source: https://www.reddit.com/r/gamedev/comments/2i9edg/code_to_create_filters_for_colorblind/
//
/* Comment on http://kaioa.com/node/75#comment-247 states that:

ColorMatrix? Nope, won't work.
You're right, the ColorMatrix version is very simplified, and not accurate. I created that color matrix one night (http://www.colorjack.com/labs/colormatrix/)
and since then it's shown up many places... I should probably take that page down before it spreads more! Anyways, it gives you an idea of what it might look
like, but for the real thing...

As far as a simple script to simulate color blindness, this one does the best job:

http://www.nofunc.com/Color_Blindness_Library/ — It uses "confusion lines" within the XYZ color space to calculate values (this one is in Javascript, and should be easy to convert to python).

There are a few other methods, and no one really knows exactly what it would look like... these are all generalizations of a small sample, set against the masses.
*/
var allFilters = [
  'simplProtanopia', // Red blind
  'simplProtanomaly', // Red weak
  'simplDeuteranopia', // Green blind
  'simplDeuteranomaly', // Green weak
  'simplTritanopia', // Blue blind
  'simplTritanomaly', // Blue weak
  'simplAchromatopsia', // Black and white
  'simplAchromatomaly', // Blue as monochrome
  // 'hcirnNormal',
  // 'hcirnProtanopia',
  // 'hcirnProtanomaly',
  // 'hcirnDeuteranopia',
  // 'hcirnDeuteranomaly',
  // 'hcirnTritanopia',
  // 'hcirnTritanomaly',
  // 'hcirnAchromatopsia',
  // 'hcirnAchromatomaly',
];
var ColorMatrixMatrixes = {
  Normal: {
    R: [100, 0, 0],
    G: [0, 100, 0],
    B: [0, 0, 100 /*Fixed: was in the wrong spot in the original version*/],
  },
  Protanopia: {
    R: [56.667, 43.333, 0],
    G: [55.833, 44.167, 0],
    B: [0, 24.167, 75.833],
  },
  Protanomaly: {
    R: [81.667, 18.333, 0],
    G: [33.333, 66.667, 0],
    B: [0, 12.5, 87.5],
  },
  Deuteranopia: {
    R: [62.5, 37.5, 0],
    G: [70, 30, 0],
    B: [0, 30, 70],
  },
  Deuteranomaly: {
    R: [80, 20, 0],
    G: [25.833, 74.167, 0],
    B: [0, 14.167, 85.833],
  },
  Tritanopia: {
    R: [95, 5, 0],
    G: [0, 43.333, 56.667],
    B: [0, 47.5, 52.5],
  },
  Tritanomaly: {
    R: [96.667, 3.333, 0],
    G: [0, 73.333, 26.667],
    B: [0, 18.333, 81.667],
  },
  Achromatopsia: {
    R: [29.9, 58.7, 11.4],
    G: [29.9, 58.7, 11.4],
    B: [29.9, 58.7, 11.4],
  },
  Achromatomaly: {
    R: [61.8, 32, 6.2],
    G: [16.3, 77.5, 6.2],
    B: [16.3, 32.0, 51.6],
  },
};

function matrixFunction(matrix) {
  return function(rgb) {
    var r = rgb[0];
    var g = rgb[1];
    var b = rgb[2];
    return [
      (r * matrix.R[0]) / 100.0 +
        (g * matrix.R[1]) / 100.0 +
        (b * matrix.R[2]) / 100.0,
      (r * matrix.G[0]) / 100.0 +
        (g * matrix.G[1]) / 100.0 +
        (b * matrix.G[2]) / 100.0,
      (r * matrix.B[0]) / 100.0 +
        (g * matrix.B[1]) / 100.0 +
        (b * matrix.B[2]) / 100.0,
    ];
  };
}

var colorMatrixFilterFunctions = {};
for (var t in ColorMatrixMatrixes) {
  if (ColorMatrixMatrixes.hasOwnProperty(t)) {
    colorMatrixFilterFunctions[t] = matrixFunction(ColorMatrixMatrixes[t]);
  }
}

var imageCache = {};
var urlCache = {};
function clearImageCache() {
  imageCache = {};
  urlCache = {};
}

function getFilteredImage(img, type, callback) {
  console.log('getFilteredImage');
  if (type in imageCache) {
    callback(imageCache[type], urlCache[type]);
  } else {
    if (type === 'hcirnNormal' || type === 'simplNormal') {
      imageCache[type] = img;
      urlCache[type] = '#';
      callback(img, '#');
    } else {
      var filtered = createFilteredImage(img, type, function(filtered, url) {
        imageCache[type] = filtered;
        urlCache[type] = url;
        callback(filtered, url);
      });
    }
  }
}

function createFilteredImage(img, type, callback) {
  console.log('createFilteredImage');
  var filterFunction = getFilterFunction(type);
  var canvas = document.createElement('canvas');
  var w = img.naturalWidth;
  var h = img.naturalHeight;
  canvas.setAttribute('width', w);
  canvas.setAttribute('height', h);
  var ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  var pixels = ctx.getImageData(0, 0, w, h);

  var chunkSize = pixels.data.length;
  var i = 0;
  for (; i < pixels.data.length; i += 4) {
    var rgb = [pixels.data[i], pixels.data[i + 1], pixels.data[i + 2]];
    var filteredRGB = filterFunction(rgb);
    pixels.data[i] = filteredRGB[0];
    pixels.data[i + 1] = filteredRGB[1];
    pixels.data[i + 2] = filteredRGB[2];
  }

  NProgress.set(0.2 + 0.8 * (i / pixels.data.length));
  // Work is done
  ctx.putImageData(pixels, 0, 0);
  var url = canvas.toDataURL();

  const image = new Image();
  image.src = url;
  // document.body.append(img);
  // document.body.append(image);
  return url;
}

function getFilterFunction(type) {
  var lib;
  if (type.substring(0, 5) === 'hcirn') {
    lib = fBlind;
  } else if (type.substring(0, 5) === 'simpl') {
    lib = colorMatrixFilterFunctions;
  } else {
    throw 'Invalid Filter Type!';
  }
  type = type.substring(5);
  if (type in lib) {
    return lib[type];
  } else {
    throw 'Library does not support Filter Type: ' + type;
  }
}

export { allFilters, createFilteredImage };
