import React, { Component } from 'react';

import styles from './styles.css';
import GenderPercentage from './GenderPercentage';
import UsedColors from './UsedColors';

export default class Normal extends Component {
  render() {
    return (
      <div>
        <h2 className={styles.header}>Regular vision</h2>
        <p className={styles.paragraph}>
          This is how your design looks to those with full color vision
        </p>

        <div className={styles.colorsUsing}>
          <h3>Colors you're using</h3>
          <UsedColors />
        </div>

        <h3 className={styles.severity}>Affects</h3>
        <GenderPercentage gender="male" percentage="95" />
        <GenderPercentage gender="female" percentage="98" />
      </div>
    );
  }
}
