import React from 'react';
import PropTypes from 'prop-types';

import Male from './Male';
import Female from './Female';

import styles from './styles.css';

export default function GenderPercentage({ percentage, gender }) {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <div className={styles.circle}>
          {gender === 'female' ? <Female /> : <Male />}
        </div>
        <div className={styles.percentage}>
          {percentage}
          <span className={styles.percentageSign}>%</span>
        </div>
      </div>
    </div>
  );
}

GenderPercentage.propTypes = {
  percentage: PropTypes.string.isRequired,
  gender: PropTypes.oneOf('male', 'female').isRequired,
};
